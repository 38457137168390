<template>
<div class="view-wrapper">
  <div class="auth">
    <div class="auth-content">
      <h1 class="auth-title">Autentificare</h1>
      <p class="auth-error red-text" v-if="showErrorAuth">
        Utilizatorul sau parola nu au fost recunoscute. Incearca din nou!
      </p>
      <div class="form-wrapper">
        <b-form @submit="onSubmit">
          <b-form-group
            id="user-label"
            label="Utilizator"
            label-for="user"
            class="label-class"
          >
            <b-form-input
              id="user"
              v-model="auth.user"
              type="text"
              required
              class="input-class"
              @keydown="clean"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="pass-label"
            label="Parola"
            label-for="pass"
            class="label-class"
          >
            <b-form-input
              id="pass"
              v-model="auth.pass"
              type="password"
              required
              class="input-class"
              @keydown="clean"
            ></b-form-input>
          </b-form-group>
          <div class="auth-cta">
            <b-button
              type="submit"
              variant="primary"
              class="btn red-btn"
              @click="validateFields"
              >Autentifica-te</b-button
            >
            <div class="pass-reset" v-if="showPass">
              <p>
                <span>Ai uitat parola? </span
                ><span class="reset-wrapper"
                  ><router-link to="/recuperare-parola" class="blue-text"
                    >Recupereaz-o</router-link
                  ></span
                >
              </p>
            </div>
          </div>
        </b-form>
      </div>
      <div class="small-print">
        <p class="gray-text">
          Utilizatorul si parola au fost generate de catre administrator si
          trimise pe mail-ul oficial de firma
        </p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Authentication",
  data() {
    return {
      auth: {
        user: "",
        pass: "",
      },
      showPass:false
    };
  },
  computed: {
    showErrorAuth() {
      return this.$store.state.showErrorAuth;
    },
  },

  mounted() {
    if (sessionStorage.getItem("recov")) {
      sessionStorage.removeItem("recov");
    }
    if (sessionStorage.getItem("reset")) {
      sessionStorage.removeItem("reset");
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      const self = this;
    
      if (!self.auth.user || !self.auth.pass) {
        console.log("error");
        return;
      } else {
        self.login(self);
      }
    },
    //wait to have post reply before clearing user/pass fields
    async login(self) {
      await self.$store.dispatch("retrieveToken", {
        username: self.auth.user,
        password: self.auth.pass,
      });
      self.auth.user = "";
      self.auth.pass = "";
    },
    //clean error on input
    clean() {
      this.$store.commit("set_error_auth", false);
    },
    //show error if one field is empty
    validateFields() {
      const self = this;
      if (!self.auth.user || !self.auth.pass) {
        self.auth.user = "";
        self.auth.pass = "";
        this.$store.commit("set_error_auth", true);
      } else {
        this.$store.commit("set_error_auth", false);
      }
    },
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
}
.auth {
  padding: 23px 20px 20px 20px;

  .back-to-login {
    text-align: left;
    height: 24px;
    .fa-arrow-left {
      font-size: 24px;
      color: black;
    }
  }

  .auth-content {
    max-width: 87%;
    margin: 63px auto 0 auto;
    @media(max-height:500px){
      margin-top:30px;
    }
    .auth-title {
      font-size: 28px;
      text-align: left;
      font-weight: 500;
      margin-bottom: 21px;
      @media(max-height:500px){
        margin-bottom:0;
      }
    }
    .auth-error {
      font-size: 15px;
      margin: 0;
      text-align: left;
      font-weight: 500;
    }
  }

  .form-wrapper {
    padding-top: 31px;
    text-align: left;
    @media(max-height:500px){
      padding-top:10px;
    }
    .label-class {
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      margin-bottom: 7px;
    }
    .input-class {
      border: 1px solid #000;
      height: 55px;
      margin-bottom: 15px;
    }
    .auth-cta {
      margin-top: 39px;
      text-align: center;
       @media(max-height:500px){
        margin-top:20px;
      }
    }
    .pass-reset {
      margin: 0 !important;
    }
  }
  .gray-text {
    font-size: 14px;
    line-height: 17.54px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .small-print {
       position: fixed;
       bottom: 20px;
       left: 20px;
       right: 20px;
       max-width:414px;
       margin: 0 auto;
  }
}
</style>
