<template>
  <div class="appointments">
    <NavigationHeader></NavigationHeader>
    <div class="appointments-wrapper">
      <h1 class="appointment-title" v-if="!showMenu">Programari</h1>
      <p class="appointment-error" v-if="!showMenu">{{ errorMessage }}</p>

      <h1 class="appointment-title" v-if="showMenu">{{ selectedTitle }}</h1>
      <div class="date-based-wrapper" v-if="showMenu">
        <p class="appointment-date" v-if="noAppoinments">
          Nu exista programari pentru aceasta zi
        </p>
        <div v-for="(dateBlock, index) in data" :key="index">
          <p class="appointment-date" v-if="dateBlock.appointments.length">
            {{ dateBlock.date }}
          </p>

          <div
            v-for="(appointment, idx) in dateBlock.appointments"
            :key="idx"
            class="appointment-data"
          >
            <keep-alive>
              <Appointment :appointment="appointment"></Appointment>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Appointment from "../components/Appointment";
import NavigationHeader from "../components/NavigationHeader";
import logout from "../mixins/logout";
export default {
  name: "Home",
  components: { Appointment, NavigationHeader },
  props: ["when"],
  mixins: [logout],
  data() {
    return {
      errorMessage: "",
      data: "",
      noAppoinments: false,
    };
  },

  watch: {
    when: {
      //when doesn't need to be called before changed
      handler: function() {
        this.getAppointments(this.isAll,this.isAllForced);
        this.$store.commit("set_is_all_forced", false);
      },
      immediate: false,
    },
    isAll: {
      handler: function(val) {
        this.getAppointments(val,this.isAllForced);
        this.$store.commit("set_is_all_forced", false);
      },
      immediate: true,
    },
    isAllForced: {
       handler: function(val) {
         if(val){
            this.getAppointments(this.isAll,val);
            this.$store.commit("set_is_all_forced", false);
         }
      },
      immediate: true,
    }
  },
  computed: {
    selectedTitle() {
      return this.$store.state.appointmentsTitle;
    },
    showMenu() {
      return this.$store.state.showMenu;
    },
    isAll() {
      return this.$store.state.isAll;
    },
    isAllForced() {
      return this.$store.state.isAllForced;
    },
  },
  methods: {
    async getAppointments(isAll,isAllForced) {
      const self = this;
      await axios({
        method: "POST",
        url: self.appConfig.appointmentsUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: isAllForced?{ when: self.when, get_all: isAll, force_update:isAllForced}:{ when: self.when, get_all: isAll},
      })
        .then((response) => {
          const error = response.data.error;
          const resp = response.data.payload;
          if (error.hasOwnProperty("status")) {
            if ((error.status = "401")) {
              self.logOut();
            } else {
              self.$store.commit("set_show_menu", false);
              self.errorMessage = error.message;
            }
          } else {
            console.log("resp appointments", resp);
            self.$store.commit("set_show_menu", true);
            self.data = resp;

            //general no appointment message
            self.noAppoinments = true;
            resp.forEach(function(el, idx) {
              if (el.appointments.length) {
                self.noAppoinments = false;
              }
            });

            if (self.when === "past") {
              self.$store.commit("set_appointments_title", "Programari anterioare");
            } else if (self.when === "future") {
              self.$store.commit("set_appointments_title", "Programari viitoare");
            } else {
              self.$store.commit("set_appointments_title", "Programari astazi");
            }
          }
        })
        .catch((error) => {
          self.$store.commit("set_show_menu", false);
          self.$store.commit("set_when", "present");
          self.errorMessage = error.message;
          self.logOut();
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss">
.appointments {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 25px 25px;

  .date-based-wrapper {
    margin-bottom: 70px;
  }
  .appointments-wrapper {
    text-align: left;
    .appointment-title {
      font-size: 28px;
      @media(max-width:360px){
        font-size:26px;
      }
      @media(max-width:330px){
        font-size:23px;
      }
    }
    .appointment-error {
      color: #ff513b;
    }
    .appointment-date {
      font-size: 17px;
      margin-bottom: 30px;
      margin-top: 33px;
      font-weight: 500;
    }
  }
 
}
</style>
