<template>
  <div class="settings">
    <div class="nav-row">
      <a @click="refreshAppointments"><i class="fas fa-times"></i></a>
    </div>
    <h1 class="settings-title">Setari</h1>
    <a class="link refreshAppointments" @click="refreshAppointments($event)"
      >Reincarca programarile</a
    ><br>
    <a
      class="link refreshAppointments"
      @click="
        toggleIsAll($event);
      "
      >{{
        isAll
          ? "Afiseaza doar programarile mele"
          : "Afiseaza toate programarile"
      }}</a
    ><br>
    <a class="link" @click="logOut">Delogheaza-te</a>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import logout from "../mixins/logout";
export default {
  name: "Settings",
  mixins: [logout],
  beforeDestroy() {
    this.$root.$off("refreshAppointments");
  },
  computed: {
    isAll() {
      return this.$store.state.isAll;
    },
  },
  methods: {
    toggleIsAll(e) {
      this.settingsHelper();

      if (e.currentTarget.className.indexOf("refreshAppointments") > -1) {
        if(this.$route.name==="Details"){
            this.$router.push('/');
      }
      this.$store.commit("set_is_all", !this.isAll);
      sessionStorage.setItem('flow',this.isAll);
      }
    },
    refreshAppointments(e) {
      this.settingsHelper();

      if (e.currentTarget.className.indexOf("refreshAppointments") > -1) {
        if(this.$route.name==="Details"){
            this.$router.push('/');
      }
        this.$store.commit("set_is_all", this.isAll);
        //send forced update flag
        this.$store.commit("set_is_all_forced", true);
      }
    },
    settingsHelper(){
      this.$store.commit("set_show_settings", false);
      this.$store.commit("set_show_menu", true);
    }
  },
};
</script>
<style lang="scss">
.settings {
  padding: 25px 25px 25px 36px;
  background: #009ae0;
  text-align: left;
  min-height: 100vh;
  color: #fff;
  .settings-title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 81px;
  }
  .nav-row {
    text-align: right;
    color: #fff;
    display: flex;
    margin-right: auto;
    justify-content: flex-end;
    a {
      cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 50px;
          position: relative;
          left: 20px;
    }
    svg {
      color: #fff;
      font-size: 19px;
    }
  }
  .link {
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 33px;
    display: inline-block;
    cursor: pointer;
  }
}
</style>
