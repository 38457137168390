<template>
  <div class="canvas-wrapper">
    <div class="nav-row">
      <a @click="hideSignature"><i class="fas fa-times"></i></a>
    </div>
    <div class="canvas-content">
      <div class="canvas-content">
       
        <div class="canvas-comp">
          <div class="custom-canvas">
            <vueSignature ref="signature" :sigOption="option"></vueSignature>
          </div>
          <div @click="clear" class="repeat-sign">
            <i class="fas fa-history past"></i><span>Repeta semnatura</span>
          </div>
        </div>
      </div>
      <div class="error-text" v-if="showError">{{ errorMessage }}</div>
       <div class="canvas-text">
          Prin prezenta semnatura, confirm ca informatiile prezente in acest
          formular sunt corecte, si sunt de-acord cu prelucrarea datelor cu
          caracter personal conform legislatiei in vigoare.
        </div>
      <div class="status-cta" @click="sendSignature">
        <b-button
          type="submit"
          variant="primary"
          class="btn red-btn"
          id="canvas-cta"
          >Valideaza semnatura</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import vueSignature from "vue-signature";
export default {
  data() {
    return {
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      showError: false,
      disabled: false,
    };
  },
  components: {
    vueSignature,
  },
  props: ["task"],
  computed: {
    storedTask() {
      console.log(this.$store.state.taskData);
      return this.$store.state.taskData;
    },
  },
  watch: {
    task: {
      //send selected ap/op data to store
      handler: function(value) {
        if (value.id) {
          this.$store.commit("set_task_data", { initial: true, value: value });
        }
      },
      immediate: true,
    },
  },
  methods: {
    hideSignature() {
      this.$store.commit("set_show_signature", false);
      sessionStorage.removeItem("showS");
    },
    save() {
      const self = this;
      const png = self.$refs.signature.save();

      let parentId = this.$store.state.taskData.id;

      //get updated data from store
      this.$store.state.taskData.data.forEach(function(compObj, idx) {
        if (compObj.id === "signature") {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: compObj,
            idToUpdate: compObj.id,
            newVal: png,
            nested: false,
            parentId: parentId,
            signature:true
          });
        }
      });
      self.hideSignature();
    },
    clear() {
      const self = this;
      self.$refs.signature.clear();
    },
    async sendSignature() {
      const self = this;
      const parentId = self.$store.state.taskData.id;
      const png = self.$refs.signature.save();
      await axios({
        method: "POST",
        url: self.appConfig.saveSignature,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: { data: png, id: parentId },
      })
        .then((response) => {
          const error = response.data.error;
          const reponse = response.data.payload;
          if (error.hasOwnProperty("status")) {
            self.errorMessage = error.message;
            self.showError = true;
            console.error(error.status);
          } else {
            self.save();
            self.showError = false;
          }
          console.log("signature post", reponse);
        })
        .catch((error) => {
          self.showError = true;
          self.errorMessage = error.message;
          console.error(error.status);
        });
    },
  },
};
</script>
<style lang="scss">
.canvas-wrapper {
  padding: 25px;
  background: #e5e5e5;
  text-align: left;
  min-height: 100vh;
  .error-text {
    color: #ea513d;
  }
  .canvas-title {
    margin-bottom: 50px;
    font-size: 28px;
    @media(max-width:500px){
      margin-bottom:5px;
    }
  }
  .canvas-text {
    font-size: 12px;
    margin-bottom: 39px;
      @media(max-width:500px){
      margin-bottom:10px;
    }
  }
  .canvas-label {
    font-size: 14px;
    margin-bottom: 15px;
  }
  #canvas-cta {
    margin-bottom: 0;
  }
  .canvas-comp {
    margin-bottom: 20px;
    .custom-canvas {
      height: 401px;
    }
    .repeat-sign {
      margin-top: 12px;
      text-align: right;
      cursor: pointer;
      .sign-text {
        font-size: 16px;
      }
      .fa-history.past {
        position: relative;
        margin-right: 10px;
      }
    }
  }

  .nav-row {
    text-align: right;
    color: #fff;
    a {
      cursor: pointer;
    }
    svg {
      color: #000;
      font-size: 19px;
    }
  }
}
</style>
