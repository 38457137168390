<template>
  <div class="signature-wrapper task-inner-wrapper" :class="{completed:parentObjSign?true:signed}" :id="compObj.id + '-wrapper'" @click="showSignature">
    <span>{{parentObjSign?'Semnatura proprietar preluata':'Preia semnatura proprietar'}} </span>
    <div class="next-arrow"><i class="fas fa-angle-right"></i></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      userData: "",
      signed:false
    };
  },
  props: ["compObj","parentObjId","parentObjSign"],
  watch:{
    compObj :{
            handler:  function (value) {
                const self = this;
                if(value.hasOwnProperty('signature')){
                  this.signed = true;
                }
                console.log('stored data for ap',value)
            },
            immediate: true,
        },
  },
  methods:{
    showSignature(){
      window.scrollTo(0,0);
      this.$store.commit('set_show_signature',true);
      sessionStorage.setItem('showS',true);
    },
  },
};
</script>
<style lang="scss">
.signature-wrapper {
    font-size: 16px;
    background: #f2f2f2;
    border: 1px solid #cfcfcf;
    padding: 20px 23px;
    margin-bottom: 17px;
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    &.completed {
      background: #009850;
      color: #fff;
    }
  .next-arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
  }
}
</style>