// const Config={
//     appointmentsUrl:'http://localhost:3000/appointments',
//     appointmentUrl:'http://localhost:3000/appointment',
//     setAppartmentUrl:'http://localhost:3000/setApartment',
//     setContractTaskUrl:'http://localhost:3000/setContractTask',
//     logoutUrl:'http://localhost:3000/auth/logout',
//     loginUrl:'http://localhost:3000/auth/login',
//     sendReportUrl:'http://localhost:3000/sendReport',
//     sendStatusUrl:'http://localhost:3000/sendStatus',
//     forgotPass:'http://localhost:3000/auth/forgot_password',
//     saveSignature:'http://localhost:3000/saveSignature',
//     agentsUrl: 'http://localhost:3000/agents',
//     agentAppointmentUrl: 'http://localhost:3000/agentAppointments',
// };

const Config={
    appointmentsUrl:'https://api.engo.app/appointments',
    appointmentUrl:'https://api.engo.app/appointment',
    setAppartmentUrl:'https://api.engo.app/setApartment',
    setContractTaskUrl:'https://api.engo.app/setContractTask',
    logoutUrl:'https://api.engo.app/auth/logout',
    loginUrl:'https://api.engo.app/auth/login',
    sendReportUrl:'https://api.engo.app/sendReport',
    sendStatusUrl:'https://api.engo.app/sendStatus',
    forgotPass:'https://api.engo.app/auth/forgot_password',
    saveSignature:'https://api.engo.app/saveSignature',
    agentsUrl: 'https://api.engo.app/agents',
    agentAppointmentUrl: 'https://api.engo.app/agentAppointments',
};

// const Config={
//     appointmentsUrl:'https://stage-api.engo.app/appointments',
//     appointmentUrl:'https://stage-api.engo.app/appointment',
//     setAppartmentUrl:'https://stage-api.engo.app/setApartment',
//     setContractTaskUrl:'https://stage-api.engo.app/setContractTask',
//     logoutUrl:'https://stage-api.engo.app/auth/logout',
//     loginUrl:'https://stage-api.engo.app/auth/login',
//     sendReportUrl:'https://stage-api.engo.app/sendReport',
//     sendStatusUrl:'https://stage-api.engo.app/sendStatus',
//     forgotPass:'https://stage-api.engo.app/auth/forgot_password',
//     saveSignature:'https://stage-api.engo.app/saveSignature',
//     agentsUrl: 'https://stage-api.engo.app/agents',
//     agentAppointmentUrl: 'https://stage-api.engo.app/agentAppointments',
// };


export {Config};