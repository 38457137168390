<template>
  <div class="reset">
    <!-- <div class="back-to-login" v-if="!showConf">
      <a  @click="$router.go(-1)"><i class="fas fa-arrow-left"></i></a>
    </div> -->
    <div class="reset-content">
      <h1 class="reset-title" v-if="!showConf">Reseteaza parola</h1>
      <h1 class="reset-title confirmation-title" v-else>
        Parola a fost schimbata cu succes
      </h1>
      <p class="reset-text" v-if="!showConf">
        Introduceti in campul de mai jos o noua parola
      </p>
      <!-- <p class="reset-error red-text" v-if="showError">Introduceti o adresa de pass corecta
        </p> -->
      <div class="form-wrapper" v-if="!showConf">
        <b-form @submit="onSubmit">
          <b-form-group
            id="pass-label"
            label="Parola noua"
            label-for="pass"
            class="label-class"
          >
            <b-form-input
              id="pass"
              v-model="reset.pass"
              type="password"
              required
              class="input-class"
            ></b-form-input>
          </b-form-group>

          <div class="reset-cta">
            <b-button
              type="submit"
              variant="primary"
              class="btn red-btn"
              @click="validateFields"
              >Seteaza parola</b-button
            >
          </div>
        </b-form>
      </div>
      <div class="conf-content" v-else>
        <p class="reset-text">Foloseste noua parola pentru a te autentifica</p>

        <div class="conf-cta">
          <router-link to="/autentificare">
            <button id="conf-auth" class="btn red-btn" @click="deleteResetFlag">
              Autentificare
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Reset",
  data() {
    return {
      reset: {
        pass: "",
      },
      show: true,
      showConf: false,
      showError: false,
    };
  },
  mounted() {
    if (sessionStorage.getItem("reset")) {
      this.showConf = true;
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
    },
    validateFields() {
      const self = this;
      if (!self.reset.pass) {
      } else {
        self.showConf = true;
        sessionStorage.setItem("reset", "1");
      }
    },
    deleteResetFlag() {
      if (sessionStorage.getItem("reset")) {
        sessionStorage.removeItem("reset");
      }
    },
  },
};
</script>
<style lang="scss">
.reset {
  padding: 23px 20px 20px 20px;

  .back-to-login {
    text-align: left;
    height: 24px;
    cursor: pointer;
    .fa-arrow-left {
      font-size: 24px;
      color: black;
    }
  }

  .reset-content {
    max-width: 87%;
    margin: 63px auto 0 auto;
    .reset-title {
      font-size: 27px;
      text-align: left;
      font-weight: 500;
      margin-bottom: 21px;
      &.confirmation-title {
        margin-bottom: 62px;
      }
    }
    .reset-text {
      font-size: 14.5px;
      margin: 0;
      text-align: left;
      font-weight: 500;
    }
    .reset-error {
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: left;
    }
    .conf-content {
      .reset-text {
        margin-bottom: 78px;
      }
      .conf-cta {
        // margin-bottom:331px;
        .btn {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .form-wrapper {
    padding-top: 31px;
    text-align: left;
    .label-class {
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      margin-bottom: 7px;
    }
    .input-class {
      border: 1px solid #000;
      height: 55px;
      margin-bottom: 15px;
    }
    .reset-cta {
      margin-top: 32px;
      //   margin-bottom: 150px;
      text-align: center;
      // margin-bottom:382px;
      .btn {
        margin-bottom: 0 !important;
      }
    }
    .pass-reset {
      margin: 0 !important;
    }
  }
  .gray-text {
    font-size: 14px;
    line-height: 17.54px;
    font-weight: 400;
    margin-top: 150px;
    margin-bottom: 0;
  }
  .small-print {
    margin-bottom: 79px;
  }
}
</style>
