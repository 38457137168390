<template>
  <div class="checkbox-wrapper task-inner-wrapper" :id="compObj.id + '-wrapper'">
    <b-form-group>
      <b-form-radio
        v-model="selected"
        :id="compObj.id + '-yes'"
        :name="compObj.id + '-checkbox'"
        class="button-custom yes"
        :value="true"
        :ref="compObj.id + '-yes'"
        >Da</b-form-radio
      >
      <b-form-radio
        v-model="selected"
        :id="compObj.id + '-No'"
        :name="compObj.id + '-checkbox'"
        class="button-custom no"
        :value="false"
        :ref="compObj.id + '-No'"
        >Nu</b-form-radio
      >
    </b-form-group>
    <div v-if="showFields">
      <div
        v-for="(comp, idx) in compObj.components"
        :key="idx"
        class="task-outer-wrapper"
      >
        <!-- <component :is="comp.type==='checkbox'?comp.type + 'CustomNested':(comp.generates?comp.type + 'CustomGenerate':comp.type + 'Custom')" :compObj="comp"  :parentObjId="parentObjId" :parentObjValue="compObj.value" :nested="true" :generates="comp.generates?getGeneratedStructure:false" :parentLoopIdx="'el'" ></component> -->
          <component :is="comp.type==='checkbox'?comp.type + 'CustomNested':comp.type + 'Custom'" :compObj="comp"  :parentObjId="parentObjId" :parentObjValue="compObj.value" :nested="true"  ></component>
      </div>
    </div>
  </div>
</template>

  
<script>
import textfield_numerCustom from "../components/textfield_numerCustom";
import textfieldCustom from "../components/textfieldCustom";
import selectCustom from "../components/selectCustom";
import selectCustomGenerate from "../components/selectCustomGenerate";
import checkboxCustomNested from "../components/checkboxCustomNested";
export default {
  data() {
    return {
      userData: "",
      //map value to checkbox understood values
      selected: this.compObj.value === 0 ? false : this.compObj.value === 1 ? true : null,
      showFields: false,
    };
  },
  components: { textfield_numerCustom, selectCustom,checkboxCustomNested,textfieldCustom,selectCustomGenerate },
  props: ["compObj","parentObjId"],
  watch: {
    selected: {
      handler: function (value) {
        //condition for showing nested components
         const self = this;
         //show component fields if value not 0
        if (value!==0) {
          self.showFields = true;
        } else {
          self.showFields = false;
        }

        //map back value to send to db
        let newVal=null;
        if(self.selected===true){
          newVal=1;
        }else if(self.selected===false){
          newVal=0;
        }
         
         //save new value in store
         if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal:newVal,
            nested:false
          });
        }

      },
      immediate: true,
    },
  },
  computed:{
    getGeneratedStructure(){
      const self=this;
      let generatesId;
      let generatedTemplate;
      self.compObj.components.forEach(function(comp,idx){
        if(comp.generates){
          generatesId=comp.generates;
        }
        
      });
      self.compObj.components.forEach(function(comp,idx){
        if(comp.id===generatesId){
          generatedTemplate=comp;
          //  console.log(comp);
        }
      });
      return generatedTemplate;
    }
  }
};
</script>
<style lang="scss">
.checkbox-wrapper {
  .button-custom {
    width: 50%;
    height: 55px;
    position: relative;
    display: inline-block;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    line-height: 40px;
  }

  label,
  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }

  .yes input[type="radio"]:checked + label {
    background: #009850;
    color: #fff;
  }
  .no input[type="radio"]:checked + label {
    background: #ff513b;
    color: #fff;
  }

  label {
    padding: 5px;
    border: 1px solid #d8d8d8;

    cursor: pointer;
    z-index: 90;
  }
  .yes label {
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .no label {
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #d8d8d8;
  }

  label:hover {
    background: #ddd;
  }
  .custom-radio .custom-control-label::before {
    opacity: 0;
  }
}
</style>