<template>
  <router-link
    :to="{ name: 'Details', params: { id: appointment.id } }"
    class="link details-link"
  >
    <div class="appointment-wrapper" :class="statusColor(appointment.status)">
      <div class="header-row">
        <div class="hour-wrapper">
          <i class="far fa-clock"></i>
          <span class="hour">{{ appointment.hour }}</span>
        </div>
        <div class="status">{{ statusText(appointment.status) }}</div>
      </div>
      <div class="second-row">{{ appointment.title }}</div>
      <div class="third-row">
        <span>{{ formattedAddress }}</span>
      </div>
      <div class="next-arrow"><i class="fas fa-angle-right"></i></div>
    </div>
  </router-link>
</template>

<script>
import axios from "axios";
export default {
  name: "Appointment",
  props: ["appointment"],
  data() {
    return {
      showContent: true,
      errorMessage: "",
      address: {
        street: "",
        number: "",
        building: "",
        entrance: "",
        apartment: "",
        sector: "",
      },
      formattedAddress: "",
    };
  },
  created(){
     window.scrollTo(0,0);
  },
  watch: {
    appointment: {
      handler: function (value) {
        const self = this;
        if (value) {
          let address = "";
          if (value.address.street) {
            self.address.street = "Str " + value.address.street;
            address += self.address.street;
          }
          if (value.address.street_number) {
            self.address.number = ", Nr. " + value.address.street_number;
            address += self.address.number;
          }
          if (value.address.building) {
            self.address.building = ", Bl. " + value.address.building;
            address += self.address.building;
          }
          if (value.address.entrance) {
            self.address.entrance = ", Sc. " + value.address.entrance;
            address += self.address.entrance;
          }
          if (value.address.apartment) {
            self.address.apartment = ", Ap. " + value.address.apartment;
            address += self.address.apartment;
          }
          if (value.address.sector) {
            self.address.sector = ", Sector " + value.address.sector;
            address += self.address.sector;
          }
          self.formattedAddress = address;
        }
      },
      immediate: true,
    },
  },

  methods: {
    statusColor(status) {
      if (status === 3) {
        return "green";
      } else if (status === 2) {
        return "red";
      } else {
        return "blue";
      }
    },
    statusText(status) {
      if (status === 3) {
        return "raport trimis";
      } else if (status === 2) {
        return "in curs";
      } else {
        return "nou";
      }
    },
  },
};
</script>
<style lang="scss">
.details-link {
  &:hover {
    text-decoration: none;
  }
}
.appointment-wrapper {
  position: relative;
  border-radius: 10px;
  color: #fff;
  padding: 18px 20px 30px 20px;
  margin-bottom: 20px;
  background: yellow;
  &.red {
    background: #ff513b;
  }
  &.green {
    background: #009850;
  }
  &.blue {
    background: #009ae0;
  }
  .hour-wrapper {
    display: inline-block;
    margin-bottom: 15px;
    svg {
      padding-right: 8px;
      font-size: 24px;
      position: relative;
      top: 4px;
    }
    .hour {
      font-size: 14px;
    }
  }
  .status {
    float: right;
    display: inline-block;
    font-size: 10px;
    padding: 2px 15px;
    border: 1px solid #fff;
    border-radius: 40px;
    text-transform: uppercase;
    line-height: 12px;
  }

  .second-row {
    font-size: 18px;
    margin-bottom: 8px;
    font-weight: 600;
  }
  .third-row {
    font-size: 13px;
    margin-bottom: 2%;
  }
  .next-arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
  }
}
</style>
