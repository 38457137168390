import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router';
import { v1 as uuidv1 } from 'uuid';
import {Config} from '../config/config';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('user_flag') || null,
    showErrorAuth: false,
    appointmentsTitle: 'Programari astazi',
    showSettings: false,
    apiDetailsObject: {},
    showSignature: sessionStorage.getItem('showS') || false,
    when: 'present',
    //isall=false, shows afiseaza doar prog mele. this is mounted default
    isAll: false || sessionStorage.getItem('flow')?JSON.parse(sessionStorage.getItem('flow')):false,
    isAllForced:false,
    showMenu: false,
    showAppointmentDetails: !sessionStorage.getItem("selection"),
    taskData: {},
    generated:{},
    refreshDetails:false,
    showComplete:false
  },
  getters: {
    token: state => state.token,
    showErrorAuth: state => state.showErrorAuth,
    loggedIn: state => state.token !== null,
    appointmentsTitle: state => state.appointmentsTitle,
    showSettings: state => state.showSettings,
    apiDetailsObject: state => state.apiDetailsObject,
    showSignature: state => state.showSignature,
    when: state => state.when,
    isAll: state => state.isAll,
    isAllForced: state => state.isAll,
    showMenu: state => state.showMenu,
    showAppointmentDetails: state => state.showAppointmentDetails,
    taskData: state => state.taskData,
    generated: state => state.generated,
    refreshDetails: state => state.refreshDetails,
    showComplete: state => state.showComplete,
  },
  mutations: {

    //stores result from login post
    set_token(state, payload) {
      state.token = payload;
    },
    set_error_auth(state, payload) {
      state.showErrorAuth = payload;
    },
    set_appointments_title(state, payload) {
      state.appointmentsTitle = payload;
    },
    set_show_settings(state, payload) {
      state.showSettings = payload;
    },
    set_apiDetailsObject(state, payload) {
      state.apiDetailsObject = payload;
    },
    set_show_signature(state, payload) {
      state.showSignature = payload;
    },
    set_when(state, payload) {
      state.when = payload;
    },
    set_is_all(state, payload) {
      state.isAll = payload;
    },
    set_is_all_forced(state, payload) {
      state.isAllForced = payload;
    },
    set_show_menu(state, payload) {
      state.showMenu = payload;
    },
    set_show_appointment_details(state, payload) {
      state.showAppointmentDetails = payload;
    },
    set_refresh_details(state, payload) {
      state.refreshDetails = payload;
    },
    set_generated(state, payload) {
      state.generated = payload;
    },
    set_task_data(state, payload) {
      if (payload.initial) {
        state.taskData = payload.value;
      }else if(!payload){
        state.taskData='';
      } else {
        if (!payload.nested) {
          state.taskData.data.forEach(function (arrayItem, idx) {
            if (arrayItem.id === payload.idToUpdate) {
              state.taskData.data[idx].value = payload.newVal;
              if(payload.signature){
                state.taskData.data[idx].signature = payload.signature;
                state.taskData.signature=payload.signature;
              }
            }
          });
        }else{
          state.taskData.data.forEach(function (arrayItem, idx) {
            if(arrayItem.hasOwnProperty('components')){
              arrayItem.components.forEach(function(componentItem,index){
                if(componentItem.id === payload.idToUpdate){
                  state.taskData.data[idx].components[index].value = payload.newVal;
                  if(payload.signature){
                    state.taskData.data[idx].signature = payload.signature;
                    state.taskData.signature=payload.signature;
                  }
                }
              });
            }
          });
        }
      }
    },
    set_show_complete(state, payload) {
      state.showComplete = payload;
    },
  },
  actions: {
    async retrieveToken(context, credentials) {
      var cred = {
        username: credentials.username,
        password: credentials.password
      }
      await axios({
        method: 'POST',
        url: Config.loginUrl,
        mode: 'cors',
        cache: 'no-cache',
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        data: JSON.stringify(cred)
      })
        .then(response => {
          console.log('resp login', response.data);
          let token = null;
          let authorizedUser = false;
          if (response.data.payload.result === true) {
            token = uuidv1();
            authorizedUser = true;
            context.commit('set_error_auth', false);
            context.commit('set_token', token);
            localStorage.setItem('user_flag', token);
            router.push('/');
          } else {
            token = null;
            authorizedUser = false;
            context.commit('set_error_auth', true);
          }
          context.commit('set_token', token);
          localStorage.setItem('user_flag', token);
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  modules: {
  }
})
