<template>
  <span
    class="select-wrapper-generator"
    :id="compObj.id + '-wrapper'"
    v-if="showOnParentValue"
  >
    <div :id="compObj.id + '-title'" class="subtitle">{{ compObj.label }}</div>
    <select
      v-model="selectedOption"
      class="custom-select task-wrapper"
      :id="compObj.id + '-select'"
    >
      <option :value="option" v-for="(option, idx) in options" :key="idx">
        {{ option }}
      </option>
    </select>
    <div
      v-for="(generatedItem, index) in generated"
      :key="index"
      class="task-wrapper"
    >
      <component
        :is="'selectCustom'"
        :compObj="generates"
        :parentObjId="parentObjId"
        :parentObjValue="compObj.value"
        :nested="true"
        :parentLoopIdx="++index"
      ></component>
    </div>
  </span>
</template>
<script>
// import textfield_numerCustom from "../components/textfield_numerCustom";
import selectCustom from "../components/selectCustom";
export default {
  data() {
    return {
      selectedOption: this.compObj.value,
      options: this.compObj.options,
      generated: [],
      // userInputArray: {},
    };
  },
  components: { selectCustom },
  props: ["compObj", "parentObjId", "parentObjValue", "nested", "generates"],
  watch: {
    selectedOption: {
      handler: function (value) {
        const self = this;
        self.generated = Number(value) || 0;
        if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal: self.selectedOption,
            nested: self.nested,
            parentId: self.parentObjId,
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    showOnParentValue() {
      if (this.compObj.showForParentValue === this.parentObjValue) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss">
.custom-select {
  height: 55px !important;
  font-size: 17px !important;
  width: 100%;
}
</style>