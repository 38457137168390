import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Authentication from '../views/Authentication.vue'
import Recover from '../views/Recover.vue'
import Reset from '../views/Reset.vue'
import Details from '../views/Details.vue'
import AppointmentsReport from '../views/AppointmentsReport.vue'
// import Settings from '../views/Settings.vue'
// import Programari from '../views/Programari.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  // {
  //   path: '/programari',
  //   name: 'Programari',
  //   component: Programari,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/detalii/:id',
    name: 'Details',
    props: true,
    component: Details
  },
  // {
  //   path: '/setari',
  //   name: 'Settings',
  //   component: Settings,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/autentificare',
    name: 'Authentication',
    component: Authentication
  },
  {
    path: '/recuperare-parola',
    name: 'Recover',
    component: Recover
  },
  {
    path: '/resetare-parola',
    name: 'Reset',
    component: Reset
  },

  {
    path: '/reprogramari',
    name: 'Reprogramari',
    meta: { requiresAuth: true },
    component: AppointmentsReport
  },


  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true
})

const router = new VueRouter({
  mode: "history",
  routes,
  linkActiveClass: "active"
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  // console.log('from', from.name, 'has noe token', localStorage.getItem('user_flag') === 'null' || !localStorage.getItem('user_flag'))
  // console.log(store.getters.loggedIn)
  //if trying to access page before a token was received, redirect
  if (from.name !== 'Authentication' && requiresAuth && (localStorage.getItem('user_flag') === 'null' || !localStorage.getItem('user_flag'))) {
    // console.log('not auth, go to login page');
    next('/autentificare');
    //if token was received
  } else if (requiresAuth && localStorage.getItem('user_flag') && localStorage.getItem('user_flag') !== 'null') {
    // console.log('needs auth, and has token and local');
    next();
    //prevent back btn from auth route to need auth routes, when no token
  } else if (window.popStateDetected && requiresAuth && (localStorage.getItem('user_flag') === 'null' || !localStorage.getItem('user_flag'))) {
    // console.log('from', from.name)
    // console.log('to', to.name)
    // console.log('backbnt');
    next('/autentificare');
  } else {
    // console.log('if no auth guard,allow to see this');
    next();
  }
})

export default router
