<template>
<div class="view-wrapper">
  <div class="home">
    <Appointments :when="when" v-show="!showSettings"></Appointments>
    <Settings :when="when" v-show="showSettings"></Settings>
    <div class="btn-wrapper" v-show="!showSettings">
      <span @click="loadAppointments($event, 'past')" ref="past">
        <i class="fas fa-history past"></i>
      </span>
      <span
        @click="loadAppointments($event, 'present')"
        class="selected"
        ref="present"
      >
        <i class="far fa-clock present"></i>
      </span>
      <span @click="loadAppointments($event, 'future')" ref="future">
        <i class="fas fa-history future"></i>
      </span>
    </div>
  </div>
</div>
</template>

<script>
import Appointments from "../components/Appointments";
import Settings from "../components/Settings";
export default {
  name: "Home",
  components: { Appointments, Settings },
  computed: {
    showSettings() {
      return this.$store.state.showSettings;
    },
    when() {
      return this.$store.state.when;
    },
  },
  mounted() {
    this.$store.commit("set_show_complete",false);
    if (this.when) {
      this.loadAppointments(this.$refs[this.when], this.when, true);
      sessionStorage.removeItem("selection");
      this.$store.commit("set_task_data","");
    }
  },
  methods: {
    loadAppointments(e, when, isMounted) {
      let icons = document.querySelectorAll(".btn-wrapper span");
      icons.forEach(function (el, idx) {
        el.classList.remove("selected");
      });
      if (!isMounted) {
        e.currentTarget.classList.add("selected");
      } else {
        e.classList.add("selected");
      }
      this.$store.commit("set_when", when);
    },
  },
};
</script>
<style lang="scss">
.home {
  .btn-wrapper {
    height: 59px;
    width: 191px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 24px;
    box-shadow: 1px 4px 15px 0px #aaa;
    background: #ffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 22px;
    span.selected {
      color: #ff513b !important;
    }
    svg {
      cursor: pointer;
      margin: 0 10px;
    }
    .future {
      transform: scaleX(-1);
    }
  }
}
</style>
