<template>
  <div
    class="select-wrapper task-inner-wrapper"
    :id="compObj.id + '-wrapper'"
    :ref="compObj.id + '-wrapper'"
    v-if="showOnParentValue"
    :class="{ show: !hideElement }"
  >
    <div :id="compObj.id + '-title'" class="subtitle">{{ compObj.label }}</div>
    <select
      v-model="selectedOption"
      class="custom-select"
      :id="compObj.id + '-select'"
    >
      <option :value="option" v-for="(option, idx) in options" :key="idx">
        {{ option }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedOption: this.compObj.value,
      options: this.compObj.options,
      hideElementFlag: false,
    };
  },
  props: ["compObj", "parentObjId", "parentObjValue", "nested"],
  watch: {
    selectedOption: {
      handler: function (value) {
        const self = this;
        if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal: self.selectedOption,
            nested: self.nested,
            parentId: self.parentObjId,
          });
        }
        if (self.compObj.generates) {
          self.$store.commit("set_generated", {
            id: self.compObj.generates,
            nr: self.selectedOption,
          });
        }
      },
      immediate: true,
    },
    generated: {
      handler: function (value) {
        const self = this;
        if (self.generated.hasOwnProperty("id") && self.generated.id) {
          let regexTest = new RegExp(self.generated.id + "[0-9]");

          if (regexTest.test(self.compObj.id)) {
            if (
              Number(self.compObj.id.match(/\d+/)[0]) >
              Number(self.generated.nr)
            ) {
              self.hideElementFlag = false;
            } else {
              self.hideElementFlag = true;
            }
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    showOnParentValue() {
      if (this.compObj.showForParentValue === this.parentObjValue) {
        return true;
      } else {
        return false;
      }
    },
    generated() {
      return this.$store.state.generated;
    },
    hideElement() {
      return (
        (!this.hideElementFlag && !this.compObj.id.match(/\d+/)) ||
        this.hideElementFlag
      );
    },
  },
};
</script>
<style lang="scss">
.select-wrapper.task-inner-wrapper {
  &.show {
    display: none !important;
  }
}
.custom-select {
  height: 55px !important;
  font-size: 17px !important;
  width: 100%;
}
</style>