<template>
 <div class="complete-task-screen">
       <div class="nav-row">
            <a @click="closeMessage"><i class="fas fa-times"></i></a>
      </div>
      <div class="complete-content" v-if="completeObj">
          <!-- <div class="complete-icon" v-html='completeObj.image'></div> -->
           <div class="complete-icon" :class="completeObj.class">
               <img :src="require(`../assets/images/${completeObj?completeObj.image:''}.svg`)" alt="complete-icon">
           </div>
          <div class="complete-message">{{completeObj.message}}</div>
      </div>
 </div>
</template>
<script>
export default {
  data() {
    return {
      completeTaskObject:{
           message:'Programarea a fost marcata ca incheiata. Operatiunile ce nu au putut fi efectuate vor fi reprogramate',
           image:'completetask',
           class:"complete-task"
      },
      completeReportObject:{
          message:'Programarea a fost marcata ca incheiata, iar raportul pentru lucrare a fost generat',
          image:'completereport',
          class:"complete-report"
      },
      showMessage:true
    };
  },
   computed: {
    completeObj() {
        if(this.showComplete==='task'){
            console.log(this.completeTaskObject);
            return this.completeTaskObject;
        }else if(this.showComplete==='report'){
            return this.completeReportObject;
        }else{
            return false;
        }
     
    },
    showComplete(){
       
       return this.$store.state.showComplete;
    }
  },
  methods:{
      closeMessage(){
          this.$store.commit("set_show_complete",false);
      }
  }
};
</script>
<style lang="scss">
.complete-task-screen{
    padding: 25px;
    background: #009850;
    text-align: center;
    color:#fff;
    min-height: 100vh;
    flex-direction: column;
    display: flex;
    .nav-row{
        text-align: right;
        color: #fff;
        display: flex;
        // margin-right: auto;
        justify-content: flex-end;
        a {
        cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 50px;
            position: relative;
            left: 20px;
        }
        svg {
        color: #fff;
        font-size: 30px;
        }
    }
    .complete-icon{
        margin-bottom:35px;
    }
    .complete-task{
        max-width:70px
    }
    .complete-report{
        max-width:89px;
        position: relative;
        left: 20px;

    }
    .complete-content{
        margin-top: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .complete-message{
            font-size:24px;
            @media(min-width:370px){
                font-size:28px;
            }
        }
    }

}
</style>