import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import UnfoldMoreHorizontalIcon  from 'vue-material-design-icons/UnfoldMoreHorizontal.vue';
import UnfoldLessHorizontalIcon  from 'vue-material-design-icons/UnfoldLessHorizontal.vue';
import TextBoxMultipleIcon  from 'vue-material-design-icons/TextBoxMultiple.vue';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import {Config} from './config/config.js';
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// library.add(faUserSecret)
Vue.component('UnfoldMoreHorizontal', UnfoldMoreHorizontalIcon);
Vue.component('UnfoldLessHorizontal', UnfoldLessHorizontalIcon);
Vue.component('TextBoxMultiple', TextBoxMultipleIcon);
// Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.prototype.$http = axios;
Vue.prototype.appConfig = Config
Vue.use(BootstrapVue);

 

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
