import axios from "axios";
export default{
    methods:{
        async logOut() {
            const self = this;
      
            await axios({
              method: "POST",
              url: self.appConfig.logoutUrl,
              mode: "cors",
              cache: "no-cache",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              redirect: "follow",
              referrerPolicy: "no-referrer",
              data: JSON.stringify({}),
            })
              .then((response) => {
                localStorage.removeItem("user_flag");
                self.$store.commit("set_token", null);
                self.$store.commit("set_show_signature", false);
                sessionStorage.removeItem("showS");
                sessionStorage.removeItem("flow");
                self.$store.commit("set_show_menu",false);
                self.$store.commit("set_is_all",false);
                
              })
              .catch((error) => {
                console.log(error);
              });
              self.$store.commit("set_show_settings", false);  
              self.$router.push("/autentificare");
          },
    }
}