<template>
<div  :id="compObj.id + '-wrapper'" class="task-inner-wrapper">
   <b-form-textarea
     :id="compObj.id + '-textarea'" 
      v-model="textArea"
      rows="2"
     
    ></b-form-textarea>
</div>    
</template>
<script>
export default {
    data() {
    return {
      textArea: this.compObj.value !== null ? this.compObj.value : "",
    };
  },
   props: ["compObj","parentObjId","parentObjValue","nested"],
   watch: {
    textArea: {
      handler: function (value) {
        const self = this;
        if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal: self.textArea,
            nested:self.nested,
            parentId:self.parentObjId
          });
        }
      },
      immediate: true,
    },
  },
}
</script>
<style>
#textarea{
  border: 1px solid #9B9B9B;
}
</style>