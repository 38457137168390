<template>
  <div class="tasks" id="tasks">
    <h1 class="details-title">
      {{
        storedTask.type === "ap"
          ? "Apartament " + storedTask.number
          : storedTask.label
      }}
    </h1>
    <b-form @submit.prevent.stop>
      <div
        v-for="(comp, idx) in storedTask.data"
        :key="idx"
        class="task-outer-wrapper"
      >
        <div :id="comp.id" class="subtitle">{{ comp.label }}</div>
        <component
          :is="comp.type + 'Custom'"
          :compObj="comp"
          :parentObjId="storedTask.id"
          :parentObjSign="
            storedTask.type === 'ap' ? storedTask.signature : false
          "
          :nested="false"
          :parentObjValue="false"
        ></component>
      </div>
      <b-button
        type="submit"
        variant="primary"
        class="btn red-btn"
        @click="markComplete"
        ok-only
        >Salveaza</b-button
      >
      <b-modal
        id="modal-sign"
        title="signature"
        class="modal-signature"
        hide-footer
        hide-header
        centered
      >
        <div class="atention-icon">
          <img
            :src="require(`../assets/images/coolicon.svg`)"
            alt="complete-icon"
          />
        </div>
        <p class="warning-text">
          Nu poti marca un apartament ca “complet” daca nu preiei semnatura.
        </p>
        <b-button
          class="mt-3 btn btn red-btn btn-primary"
          block
          @click="signatureModal"
          >Preia Semnatura</b-button
        >
      </b-modal>
    </b-form>
  </div>
</template>
<script>
import textfieldCustom from "../components/textfieldCustom";
import textareaCustom from "../components/textareaCustom";
import checkboxCustom from "../components/checkboxCustom";
import selectCustom from "../components/selectCustom";
import signatureCustom from "../components/signatureCustom";
import axios from "axios";
export default {
  name: "Task",
  components: {
    textfieldCustom,
    textareaCustom,
    checkboxCustom,
    selectCustom,
    signatureCustom,
  },
  data() {
    return {
      hasSignature: this.storedTask ? this.storedTask.signature : false,
    };
  },
  props: ["task", "contractId"],
  watch: {
    task: {
      handler: function (value) {
        if (value.id) {
          this.$store.commit("set_task_data", { initial: true, value: value });
        } else if (!value.hasOwnProperty("id")) {
          this.$store.commit("set_task_data", "");
        }
        console.log("value from ap/op saved in store", value);
      },
      immediate: true,
    },
  },
  mounted() {
    console.log("hey", this.storedTask, this.hasSignature, this.compObj);
  },

  computed: {
    storedTask() {
      console.log("stored", this.$store.state.taskData);
      return this.$store.state.taskData;
    },
    showAppointmentDetails() {
      return this.$store.state.showAppointmentDetails;
    },
    showSignature() {
      return this.$store.state.showSignature;
    },
  },
  methods: {
    signatureModal() {
      console.log("has sign", this.hasSignature);
      if (!this.hasSignature) {
        window.scrollTo(0, 0);
        this.$store.commit("set_show_signature", true);
        sessionStorage.setItem("showS", true);
        this.$bvModal.hide("modal-sign");
      }
    },
    async markComplete() {
      const self = this;
      let updateData, postUrl;
      if (self.$store.state.taskData.type === "ap") {
        postUrl = self.appConfig.setAppartmentUrl;
        updateData = {
          id: self.$store.state.taskData.id,
          data: self.$store.state.taskData.data,
        };
      } else {
        updateData = {
          id: self.contractId,
          taskid: self.$store.state.taskData.id,
          data: self.$store.state.taskData.data,
        };
        postUrl = self.appConfig.setContractTaskUrl;
      }
      console.log("markComplete data", updateData, "post url", postUrl);
      console.log(self.storedTask.signature);
      // if (self.$store.state.taskData.type === "ap") {
      //   if (self.storedTask.signature) {
      //     self.hasSignature = true;
      //     self.$bvModal.hide("modal-sign");
      //   } else {
      //     self.hasSignature = false;
      //     self.$bvModal.show("modal-sign");
      //     return;
      //   }
      // }

      await axios({
        method: "POST",
        url: postUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: updateData,
      })
        .then((response) => {
          const error = response.data.error;
          const reponse = response.data.payload;
          if (error.hasOwnProperty("status")) {
            console.log("resp task post error", response.data.error);
          } else {
            self.$store.commit("set_show_appointment_details", true);
            self.$store.commit("set_refresh_details", true);
            self.$store.commit("set_generated", {});
            sessionStorage.removeItem("selection");
            window.scrollTo(0, 0);
            console.log("post successful");
          }
        })
        .catch((error) => {
          console.log("post task error", error);
        });
      
      console.log("sign?", this.storedTask);
    },
  },
};
</script>
<style lang="scss">
#tasks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 25px;
  text-align: left;

  .details-title {
    padding: 0 !important;
    text-align: left;
    font-size: 28px;
    margin-bottom: 10px;
    font-size: 500;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .task-wrapper {
    margin-bottom: 22px;
    input {
      width: 100%;
      height: 55px;
      font-size: 17px;
      border: 1px solid #000;
      border-radius: 4px;
    }
    textarea {
      border: 1px solid #000;
    }
  }
}
.modal-open {
  padding: 0 !important;
}
.modal-backdrop {
  opacity: 0.75 !important;
}
#modal-sign {
  .modal-content {
    max-width: 400px;
    margin: 0 20px;
  }
  .modal-body {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px;
    padding: 28px;
    border-radius: 20px;
    .atention-icon {
      margin-bottom: 27px;
    }
    .warning-text {
      font-size: 18px;
      text-align: center;
      font-family: "Poppins";
      color: #000;
    }
  }
  .btn {
    font-family: "Poppins";
    background: #ff513b;
    color: #fff;
    font-size: 18px;
    height: 55px;
    font-weight: 700;
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    margin: 0 auto;
    min-width: 100%;
    font-size: 18px;
    border-radius: 5px;
  }
}
</style>
