<template>
  <div :id="compObj.id + '-wrapper'" class="custom-input-wrapper task-inner-wrapper">
    <input type="text" v-model="userInput" :id="compObj.id + '-input'" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInput: this.compObj.value,
    };
  },
 
  props: ["compObj", "parentObjId","parentObjValue","nested"],
  watch: {
    userInput: {
      handler: function (value) {
        const self = this;
        if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal: self.userInput,
            nested:self.nested,
            parentId:self.parentObjId
          });
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss">
.custom-input-wrapper {
  input {
   padding: 0 23px;
  }
}
</style>