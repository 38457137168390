<template>
  <div
    :id="compObj.id + '-wrapper'"
    class="custom-input-wrapper task-inner-wrapper"
    :class="{ show: !hideElement }"
    v-if="showOnParentValue"
  >
    <div :id="compObj.id + '-title'" class="subtitle">
      {{ compObj.label }}
    </div>
    <input type="number" v-model="userInput" :id="compObj.id + '-input'" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      userInput: this.compObj.value,
      hideElement: false,
    };
  },
  props: ["compObj", "parentObjId", "parentObjValue", "nested"],
  watch: {
    userInput: {
      handler: function (value) {
        const self = this;
        if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal: self.userInput,
            nested: self.nested,
            parentId: self.parentObjId,
          });
        }
      },
      immediate: true,
    },
    generated: {
      handler: function (value) {
        const self = this;
        if (self.generated.hasOwnProperty("id") && self.generated.id) {
          let regexTest = new RegExp(self.generated.id + "[0-9]");
          if (regexTest.test(self.compObj.id)) {
            if (
              Number(self.compObj.id.match(/[0-9]/)[0]) >
              Number(self.generated.nr)
            ) {
              self.hideElement = false;
            } else {
              self.hideElement = true;
            }
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    showOnParentValue() {
      if (this.compObj.showForParentValue === this.parentObjValue) {
        return true;
      } else {
        return false;
      }
    },
    generated() {
      return this.$store.state.generated;
    },
  },
};
</script>
<style lang="scss">
.custom-input-wrapper {
  &.show {
    display: none !important;
  }
  input {
    padding: 0 10px 0 23px;
    border: 1px solid #d8d8d8 !important;
    position: static !important;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
}
</style>