<template>
<div class="view-wrapper">
  <div class="details-page-wrapper" v-show="!showComplete">
    <NavigationHeader v-if="!showSignature && !showSettings"></NavigationHeader>
    <Settings :when="when" v-show="showSettings"></Settings>
    <div class="details" v-if="!showSignature && !showSettings">
      <div class="details-wrapper" v-if="showAppointmentDetails">
        <h1 class="details-title" v-if="showMenu">{{ details.title }}</h1>
        <div class="details-content" v-if="showMenu">
          <span
            v-b-toggle.collapse-1
            variant="primary"
            class="expand"
            @click="toggleExpand"
          >
            <UnfoldMoreHorizontal v-if="expand" />
            <UnfoldLessHorizontal v-if="!expand" />
          </span>
          <div class="contact-area">
            <div class="date-wrapper icon-wrapper">
              <!-- <CalendarBlank/> -->
              <!-- <font-awesome-icon icon="fa-calendar" /> -->
              <i class="far fa-calendar"></i>
              <span class="date">{{ details.date }}</span>
            </div>
            <div class="hour-wrapper icon-wrapper">
              <!-- <ClockTimeFourOutline/> -->
              <i class="far fa-clock"></i>
              <span class="hour">{{ details.hour }}</span>
            </div>
          </div>
          <div class="address-wrapper icon-wrapper">
            <!-- <MapMarker/> -->
            <i class="fas fa-map-marker-alt"></i>
            <a
              target="_blank"
              :href="
                'http://maps.google.com/?q=Bucuresti ' +
                address.street +
                address.gmapsNr
              "
            >
              <span class="address">{{ formattedAddress }}</span></a
            >
          </div>
          <div class="contact-wrapper icon-wrapper">
            <i class="fas fa-address-book"></i>
            <span class="contact"
              >Contact: {{ details.contract.contact.name }} /
              <a :href="'tel:' + details.contract.contact.phone">{{
                details.contract.contact.phone
              }}</a></span
            >
          </div>
          
          <b-collapse id="collapse-1" class="mt-2">
            <div class="file-wrapper icon-wrapper">
              <i class="fas fa-file"></i>
              <span class="file">Numar lucrare: {{ details.contract.nr_dosar }}</span>
            </div>
            <div class="type-wrapper icon-wrapper">
              <i class="fas fa-briefcase"></i>
              <span class="type">Tip lucrare: {{ details.contract.type }}</span>
            </div>
            <div class="other-wrapper icon-wrapper">
              <TextBoxMultiple />
              <!-- <i class="fas fa-file-alt"></i> -->
              <span class="other">Detalii executie:</span>
              <div v-for="(line, idx) in formattedDetails" :key="idx">
                <div class="line-item">- {{ line.trim() }}</div>
              </div>
            </div>
            <div class="other-wrapper icon-wrapper">
              <!-- <i class="fas fa-file-alt"></i> -->
              <TextBoxMultiple />
              <span class="other1"
                >Alte info: 
              </span>
               <div v-for="(line, idx) in formattedAdditionalInfo" :key="idx">
                <div class="line-item">   {{ line.trim() }}</div>
              </div>
            </div>
            <div class="agent-wrapper icon-wrapper">
              <!-- <Account/> -->
              <i class="fas fa-user-alt"></i>
              <span class="agent"
                >Agent: {{ details.contract.agent.name }} /
                <a :href="'tel:' + details.contract.contact.phone">{{
                  details.contract.agent.phone
                }}</a></span
              >
            </div>
          </b-collapse>
        </div>
        <div class="details-form-wrapper" v-if="showMenu">
          <div class="appartment-content">
            <div class="appartment-title subtitle">
              Operatiuni per apartament
            </div>
             <div v-if="!apartments.length">Nu exista apartamente in programare</div>
            <div class="appartment-btns-wrapper">
              <div
                class="appartment-btn-wrapper"
                :class="{ completed: ap.status == 1 || ap.status == 3, reschedule: ap.status == 2}"
                v-for="(ap, idx) in apartments"
                :key="idx"
                :id="ap.id"
                :ref="ap.id"
                @click="createComponent(ap, 'ap')"
              > 
               
                <div class="appartment-btn">
                  <span>{{ ap.number }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="common-content" v-if="showMenu">
            <div class="common-title subtitle">Operatiuni comune</div>
            <div class="common-btns-wrapper">
              <div
                class="common-btn-wrapper"
                :class="{ completed: op.status == 1 ? 1 : 0 }"
                v-for="(op, idx) in operations"
                :key="idx"
                :id="op.id"
                :ref="op.id"
                @click="createComponent(op, 'op')"
              >
                <div class="common-btn">
                  <span>{{ op.label }}</span>
                  <div class="next-arrow">
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-form @submit.prevent>
            <div class="additional-content" v-if="showMenu">
              <div class="additional-title">
                Informatii aditionale despre lucrare / programare
              </div>
              <textarea
                name=""
                cols="30"
                rows="7"
                id="additional-info"
                v-model="additionalInfo"
                class="additional-info"
              ></textarea>
            </div>
            <p class="cta-error" v-if="showError">{{ errorMessage }}</p>
            <div>
              <div class="status-cta">
                <b-button
                  type="submit"
                  variant="primary"
                  class="btn red-btn"
                  id="status-cta"
                  @click="sendReport"
                  >Salveaza</b-button
                >
              </div>
              
            </div>
          </b-form>
        </div>
      </div>

      <!-- second view, ap/op tasks -->
      <Task
        class="task-wrapper details-wrapper"
        v-if="!showAppointmentDetails && !loading"
        :task="selectedOption"
        :contractId="details.id"
      >
      </Task>
    </div>
    <Signature v-if="showSignature" :task="selectedOption"> </Signature>
  </div>
  <Complete-Task v-show="showComplete"></Complete-Task>
  </div>
</template>
<script>
import axios from "axios";
import logout from "../mixins/logout";
import Task from "../components/Task";
import Signature from "../components/Signature";
import NavigationHeader from "../components/NavigationHeader";
import Settings from "../components/Settings";
import CompleteTask from "../components/CompleteTask";
export default {
  name: "Details",
  components: { Task, Signature, NavigationHeader, Settings ,CompleteTask},
  props: ["id"],
  mixins: [logout],
  data() {
    return {
      errorMessage: "",
      showError: false,
      expand: true,
      //details object retrieved from api
      details: "",
      formattedDetails: [],
      apartments: [],
      operations: [],
      additionalInfo: "",
      formattedAdditionalInfo:[],
      // refreshDetailsFlag:false,
      address: {
        street: "",
        number: "",
        building: "",
        entrance: "",
        apartment: "",
        sector: "",
        gmapsNr: "",
      },
      //app/op obj passed to task component
      selectedOption: {},
      formattedAddress: "",
      loading:true
    };
  },
  computed: {
    showSignature() {
      return this.$store.state.showSignature;
    },
    when() {
      return this.$store.state.when;
    },
    showSettings() {
      return this.$store.state.showSettings;
    },
    showMenu() {
      return this.$store.state.showMenu;
    },
    showAppointmentDetails() {
      return this.$store.state.showAppointmentDetails;
    },
    refreshDetails(){
        return this.$store.state.refreshDetails;
    },
    showComplete(){
       return this.$store.state.showComplete;
    }
  },
  watch: {
    details: {
      handler: function (value) {
        const self = this;
        if (self.details.hasOwnProperty("contract")) {
          var details = value.contract.details
            .replace(";", "***")
            .replace(",", "***")
            .replace("-", "***")
            .split("***");
          self.formattedDetails = details;

          let address = "";
          if (value.address.street) {
            self.address.street = "Str " + value.address.street;
            address += self.address.street;
          }
          if (value.address.number) {
            self.address.number = ", Nr. " + value.address.number;
            self.address.gmapsNr = parseInt(self.address.number);
            address += self.address.number;
          }
          if (value.address.building) {
            self.address.building = ", Bl. " + value.address.building;
            address += self.address.building;
          }
          if (value.address.entrance) {
            self.address.entrance = ", Sc. " + value.address.entrance;
            address += self.address.entrance;
          }
          if (value.address.apartment) {
            self.address.apartment = ", Ap. " + value.address.apartment;
            address += self.address.apartment;
          }
          if (value.address.sector) {
            self.address.sector = ", Sector " + value.address.sector;
            address += self.address.sector;
          }
          self.formattedAddress = address;
          
          var additionalInfo = value.contract.additional_info
            .split("|");
          self.formattedAdditionalInfo = additionalInfo;
        }
      },
      immediate: true,
    },
    refreshDetails :{
         handler: async function (value) {
            const self = this;
            if(value){
              await self.getDetails();
              self.$store.commit("set_refresh_details",false);
            }
        },
        immediate: true,
    }
  },
  created() {
    //reset show menu, since template depends on retrieved content
    this.$store.commit("set_show_menu", false);
     window.scrollTo(0,0);
  },
  async mounted() {
    const self = this;
    self.showError = false;

    // self.$root.$on("refreshDetails", async (param) => {
    //   await self.getDetails();
    //   // self.$root.$off("refreshDetails");
    // });
   
  
    await self.getDetails();
    

    //on refresh, find saved task details in session
    //update selectedOption based on finding
    //and pass it to Task
    if (sessionStorage.getItem("selection")) {
      if (JSON.parse(sessionStorage.getItem("selection")).type === "ap") {
        self.apartments.forEach(function (appObj, idx) {
          if (
            appObj.id === JSON.parse(sessionStorage.getItem("selection")).id
          ) {
            self.selectedOption = appObj;
            self.selectedOption.type = "ap";
          }
        });
      } else {
        self.operations.forEach(function (opObj, idx) {
          if (opObj.id === JSON.parse(sessionStorage.getItem("selection")).id) {
            self.selectedOption = opObj;
            self.selectedOption.type = "op";
          }
        });
      }
    } else {
      //if no task in session, show appoinment
      self.$store.commit("set_show_appointment_details", true);
    }
  },
  methods: {
    async getDetails() {
      const self = this;
      await axios({
        method: "POST",
        url:  self.appConfig.appointmentUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: { id: self.id },
      })
        .then((response) => {
          const error = response.data.error;
          const reponse = response.data.payload;
          if (error.hasOwnProperty("status")) {
            console.log("respo error", response.data.error);
            self.logOut();
          } else {
            console.log("resp detailss", reponse);
            self.$store.commit("set_show_menu", true);
            self.details = reponse;
            self.operations = self.details.contract.data;
            self.apartments = self.details.contract.apartments;
            self.$store.commit("set_task_data", { initial: true, value: "" });
            self.loading = false;
            // this.$store.commit("set_show_appointment_details", true );
          }
        })
        .catch((error) => {
          self.$store.commit("set_show_menu", false);
          self.$store.commit("set_when", "present");
          self.errorMessage = error.message;
          self.logOut();

          console.log(error);
        });
    },
    toggleExpand() {
      this.expand = !this.expand;
    },
    createComponent(op, type) {
      window.scrollTo(0,0);
      const self = this;
      self.$store.commit("set_show_appointment_details", false);
      self.selectedOption = op;
      self.selectedOption.type = type;
      sessionStorage.setItem("selection", JSON.stringify({ type, id: op.id }));
      console.log("selected", op);
    },
    async sendReport() {
      const self = this;
      const data = { id: self.details.id, data: self.additionalInfo };
      await axios({
        method: "POST",
        url:  self.appConfig.sendReportUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: data,
      })
        .then((response) => {
          const error = response.data.error;
          const reponse = response.data.payload;
          if (error.hasOwnProperty("status")) {
            self.errorMessage = error.message;
            self.showError = true;
          } else {
            console.log("sent report", reponse);
            self.showError = false;
            self.$root.$emit("refreshDetails", true);
            self.$store.commit("set_show_complete","report");
          }
        })
        .catch((error) => {
          self.showError = true;
          self.errorMessage = error.message;
        });
    },
    async sendStatus() {
      const self = this;
      const data = { id: self.details.id, data: self.additionalInfo };
      console.log(data)
      await axios({
        method: "POST",
        url: self.appConfig.sendStatusUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: data,
      })
        .then((response) => {
          const error = response.data.error;
          const reponse = response.data.payload;
          if (error.hasOwnProperty("status")) {
            self.errorMessage = error.message;
            self.showError = true;
          } else {
            console.log("sent status", reponse);
            self.showError = false;
            self.$root.$emit("refreshDetails", true);
            self.$store.commit("set_show_complete","task");
          }
        })
        .catch((error) => {
          self.showError = true;
          self.errorMessage = error.message;
        });
    },
  },
};
</script>
<style lang="scss">
.details-page-wrapper {
  .nav-wrapper {
    padding: 25px 25px 10px;
  }
}
.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  

  #collapse-1 {
    margin: 0 !important;
  }

  .details-title {
    padding: 0 25px;
    text-align: left;
    font-size: 28px;
    margin-bottom: 40px;
    font-size: 500;
  }

  .details-content {
    background: #f2f2f2;
    padding: 25px;
    position: relative;
    .other-wrapper {
      .line-item {
        font-size: 14px;
        margin-left: 30px;
      }
    }
    .date-wrapper {
      margin-top: 0 !important;
    }

    .icon-wrapper {
      text-align: left;
      margin-top: 15px;
      svg {
        text-align: center;
        padding-right: 8px;
        font-size: 20px;
        position: relative;
        top: 2px;
        width: 30px;
      }
      span {
        font-size: 14px;
      }
    }
    .fa-map-marker-alt {
      &:before {
        border-bottom: 2px solid #2c3e50;
      }
    }
    .material-design-icon {
      width: 30px;
      display: inline-block;
    }
    .expand {
      float: right;
      background: none;
      border: none;
      outline: none;
      position: absolute;
      top: 10px;
      right: 25px;
      &:hover {
        float: right;
        background: none !important;
        border: none !important;
        outline: none !important;
      }
      svg {
        color: #000;
      }
    }
  }
  .details-form-wrapper {
    text-align: left;
    font-size: 14px;
    padding: 25px;

    .subtitle {
      font-size: 17px;
      font-weight: 500;
    }
    .appartment-content {
      margin-bottom: 47px;

      .appartment-title {
        margin-bottom: 11px;
        margin-top: 14px;
      }
      .appartment-btns-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;

        .appartment-btn-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 73px;
          height: 73px;
          border-radius: 50%;
          border: 1px solid #bebebe;
          margin-top: 12px;
          cursor: pointer;
          &.completed {
            background: #009850;
            color: #fff;
          }
          &.reschedule {
            background: #FF513B;
            color: #fff;
          }
          &.skip {
            background: #757776;
            color: #fff;
          }
          span {
            font-size: 24px;
          }
        }
      }
    }
    .common-content {
      margin-bottom: 31px;
      .common-title {
        margin-bottom: 17px;
      }
      .common-btn-wrapper {
        font-size: 16px;
        background: #f2f2f2;
        border: 1px solid #cfcfcf;
        padding: 26px 23px;
        margin-bottom: 17px;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        &.completed {
          background: #009850;
          color: #fff;
        }
      }
      .next-arrow {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(0, -50%);
      }
    }
    .additional-content {
      margin-bottom: 23px;
      .additional-title {
        font-size: 14px;
        margin-bottom: 10px;
      }
      .additional-info {
        width: 100%;
        padding: 0.375rem 0.75rem;
      }
    }
    #submit-cta {
      background: #009850 !important;
      margin-bottom: 10px;
    }
    #status-cta {
      margin-bottom: 32px;
    }
    .cta-error {
      margin-bottom: 0;
      color: red;
    }
  }
}
</style>
