<template>
  <div class="recov">
    <div class="back-to-login" v-if="!showConf">
      <a @click="$router.go(-1)"><i class="fas fa-arrow-left"></i></a>
    </div>
    <div class="recov-content">
      <h1 class="recov-title" v-if="!showConf">Recupereaza parola</h1>
      <h1 class="recov-title confirmation-title" v-else>
        Parola a fost trimisa pe e-mail
      </h1>
      <p class="recov-text" v-if="!showConf">
        Introduceti in campul de mai jos adresa de email de firma
      </p>
      <p class="recov-error red-text" v-if="showError">
        Introduceti o adresa de email corecta
      </p>
      <div class="form-wrapper" v-show="!showConf">
        <b-form @submit="onSubmit">
          <b-form-group
            id="email-label"
            label="E-mail"
            label-for="email"
            class="label-class"
          >
            <b-form-input
              id="email"
              v-model="recov.email"
              type="email"
              required
              class="input-class"
            ></b-form-input>
          </b-form-group>

          <div class="recov-cta">
            <b-button
              type="submit"
              variant="primary"
              class="btn red-btn"
              @click="validateFields"
              >Trimite solicitare</b-button
            >
          </div>
        </b-form>
      </div>
      <div class="conf-content"  v-show="showConf">
        <p class="recov-text">
          In maxim 2 minute vei primi un e-mail cu parola ta. Daca nu ai primit,
          verifica si directorul numit Spam. Apasa pe “Autentificare” dupa ce ai
          recuperat parola.
        </p>

        <div class="conf-cta">
          <router-link to="/autentificare">
            <button id="conf-auth" class="btn red-btn" @click="deleteResetFlag">
              Autentificare
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Recover",
  data() {
    return {
      recov: {
        email: "",
      },
      show: true,
      showConf: false,
      showError: false,
    };
  },
  mounted() {
    if (sessionStorage.getItem("recov")) {
      this.showConf = true;
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.sendEmail();
    },
    async sendEmail(){
      const self = this;
      await axios({
        method: "POST",
        url:  self.appConfig.forgotPass,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: { email:this.recov.email },
      })
        .then((response) => {
          const error = response.data.error;
          const reponse = response.data.payload;
          console.log('recov pass post',reponse);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    validateFields() {
      const self = this;
      if (!self.recov.email || !self.recov.email.match(/\S+@\S+\.\S+/)) {
        self.recov.email = "";
        self.showError = true;
      } else {
        self.showConf = true;
        self.showError = false;
        sessionStorage.setItem("recov", "1");
       
      }
    },
    deleteResetFlag() {
      if (sessionStorage.getItem("recov")) {
        sessionStorage.removeItem("recov");
      }
    },
  },
};
</script>
<style lang="scss">
.recov {
  padding: 23px 20px 20px 20px;

  .back-to-login {
    text-align: left;
    height: 24px;
    cursor: pointer;
    .fa-arrow-left {
      font-size: 24px;
      color: black;
    }
  }

  .recov-content {
    max-width: 87%;
    margin: 63px auto 0 auto;
    .recov-title {
      font-size: 27px;
      text-align: left;
      font-weight: 500;
      margin-bottom: 21px;
      &.confirmation-title {
        margin-bottom: 62px;
      }
    }
    .recov-text {
      font-size: 14.5px;
      margin: 0;
      text-align: left;
      font-weight: 500;
    }
    .recov-error {
      margin-top: 1rem;
      margin-bottom: 0;
      text-align: left;
    }
    .conf-content {
      .recov-text {
        margin-bottom: 78px;
      }
      .conf-cta {
        // margin-bottom:331px;
        .btn {
          margin-bottom: 0 !important;
        }
      }
    }
  }

  .form-wrapper {
    padding-top: 31px;
    text-align: left;
    .label-class {
      font-size: 13px;
      line-height: 17px;
      font-weight: 500;
      margin-bottom: 7px;
    }
    .input-class {
      border: 1px solid #000;
      height: 55px;
      margin-bottom: 15px;
    }
    .recov-cta {
      margin-top: 32px;
      //   margin-bottom: 150px;
      text-align: center;
      // margin-bottom:382px;
      .btn {
        margin-bottom: 0 !important;
      }
    }
    .pass-reset {
      margin: 0 !important;
    }
  }
  .gray-text {
    font-size: 14px;
    line-height: 17.54px;
    font-weight: 400;
    margin-top: 150px;
    margin-bottom: 0;
  }
  .small-print {
    margin-bottom: 79px;
  }
}
</style>
