<template>
  <div class="wrapper-appointmentsReport">
  <div class="appointmentsReport">
    <h1>Raport rapid lucrari</h1>
    <div>Agent/a: <select class="agent_selector"  @change="agentChange">
       <option v-for="(agent, index) in agents" :key="index" :value="agent.id">
        {{ agent.name }}
       </option>
    </select></div>
    <div class="appointments-wrapper">
      <div class="appointment-date" v-for="(appointmentDate, index) in appointments" :key="index">
        <h4 v-if="appointmentDate.date">
          Programari din data: {{ appointmentDate.date }}
        </h4>

        <div
          v-for="(appointment, idx) in appointmentDate.appointments"
          :key="idx"
          class="appointment-data"
        >
          <p>
            <img width="25" v-if="appointment.title != 'Verificare / revizie tehnica periodica'" :src="require(`../assets/images/info.png`)"/>
            <img width="25" v-else-if="appointment.contract.apartments.length == 0" :src="require(`../assets/images/done.png`)" /> 
            <img width="25" v-else :src="require(`../assets/images/not-done.png`)" />
            &nbsp;&nbsp; Nr lucrare: <a :href="'https://app.energoinstalgaze.ro/dosar/' + appointment.contract.nr_dosar">{{ appointment.contract.nr_dosar }}</a>, Adresa: {{ formatAddress(appointment.address) }}, <a :href="'./detalii/' + appointment.id" target="_blank">Link programare</a></p>
          <p>Tip lucrare: {{ appointment.title }}</p>
          <p>Info teren: <span v-html="appointment.contract.info"></span></p>
          <p>Contact:   {{ appointment.contract.contact.name }} -- {{ appointment.contract.contact.phone }}</p>
          <div v-if="appointment.title == 'Verificare / revizie tehnica periodica'" >

          <p>Necesita reprogramare apartmentele:</p>
          <ul>
            <li
              v-for="(app, idx) in appointment.contract.apartments"
              :key="idx"
              class="apartment-data"
            >
              {{ app.number }} <span v-if="app.data[5].value">- {{ app.data[5].value }}</span>,
            </li>
          </ul>
        </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import axios from "axios";
import logout from "../mixins/logout";
export default {
  name: "AppointmentsReport",
  mixins: [logout],
  data() {
    return {
      agents: [],
      selectedAgent: null,
      appointments: [],
    }
  },
  mounted() {
    this.agents = this.getAgents()
  },
  computed: {
  },
  watch: {
    selectedAgent: {
      handler: function() {
        if(this.selectedAgent != -1) this.getAppointments()
      },
      immediate: false,
    },
  },
  methods: {
    async getAgents() {
      const self = this;
      await axios({
        method: "POST",
        url: self.appConfig.agentsUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: {} ,
      })
        .then((response) => {
          const error = response.data.error;
          const resp = response.data.payload;
          if (error.hasOwnProperty("status")) {
            if (error.status == 401 || error.status == 403) {
              self.logOut();
            } else {
              self.errorMessage = error.message;
            }
          } else {
            // console.log("resp agents", resp);
            self.agents = [{
              id: -1,
              name: "Selecteaza ..."
            }].concat(resp);
            self.selectedAgent = self.agents[0].id
          }
        })
        .catch((error) => {
          self.errorMessage = error.message;
          console.log(error);
          self.logOut();
        }); 
    },
    agentChange(event) {
      this.selectedAgent = parseInt(event.target.value)
    },
    async getAppointments() {
      const self = this;
      await axios({
        method: "POST",
        url: self.appConfig.agentAppointmentUrl,
        mode: "cors",
        cache: "no-cache",
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        data: { "agent_id" : this.selectedAgent } ,
      })
        .then((response) => {
          const error = response.data.error;
          const resp = response.data.payload;
          if (error.hasOwnProperty("status")) {
            if (error.status == 401 || error.status == 403) {
              self.logOut();
            } else {
              self.errorMessage = error.message;
            }
          } else {
            
            for(let i=0; i<resp.length; i++) {
              let appDate = resp[i]
              for(let j=0; j<appDate.appointments.length; j++) {
                resp[i].appointments[j].contract.apartments = self.reprogramOnly(resp[i].appointments[j].contract.apartments)
              }
              // resp[i].appointments = resp[i].appointments.filter(app => {
              //   return app.contract.apartments.length > 0
              // })
            }
            self.appointments = resp
          }
        })
        .catch((error) => {
          self.errorMessage = error.message;
          console.log(error);
          self.logOut();
        }); 
    },
    formatAddress(address) {
      let formattedAddress = "";
      if (address.street) {
        formattedAddress +=  "Str " + address.street;
      }
      if (address.street_number) {
        formattedAddress += ", Nr. " + address.street_number;
      }
      if (address.building) {
        formattedAddress += ", Bl. " + address.building;
      }
      if (address.entrance) {
        formattedAddress += ", Sc. " + address.entrance;
      }
      if (address.apartment) {
        formattedAddress += ", Ap. " + address.apartment;
      }
      if (address.sector) {
        formattedAddress += ", Sector " + address.sector;
      }
      return formattedAddress;
    },
    reprogramOnly(aps) {
      // console.log('in repgrom')
      return aps.filter(ap => {
        return ap.status != 3
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.appointmentsReport {
  width:100%;
  max-width:1024px;
  padding-top:20px;
}
.appointmentsReport h1 {
  padding-bottom:10px;
}
.agent_selector { 
  min-width:300px;
  padding-left:10px;
}
.wrapper-appointmentsReport {
  width:1024px;
  margin:0px auto;
  background-color:white;
  min-height: 100vh;
}
.appointments-wrapper {
  padding:10px 30px;

}
.appointment-date {
  text-align:left;
  margin-top: 40px;
}

.appointment-data {
  margin-top:20px;
  border-bottom:1px solid #ccc;
  padding:5px 7px;
}
.apartment-data {
  display:inline-block;
  list-style-type: none;
  padding:10px;
}

</style>