<template>
  <div class="nav-wrapper">
    <div class="nav-row">
      <a @click="dynamicBackArrow" ref="backarrow"
        ><i class="fas fa-arrow-left"></i
      ></a>
      <a
        class="link show-settings-link"
        v-if="showMenu"
        @click="showSettings()"
      >
        <i class="fas fa-ellipsis-v"></i
      ></a>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showContent: true,
    };
  },
  // props:["contractId"],
  computed: {
    showMenu() {
      return this.$store.state.showMenu;
    },
  },
  mounted() {
    if (this.$route.name !== "Details") {
      this.$refs.backarrow.classList.add("hide");
    }
  },

  methods: {
    showSettings() {
      this.$store.commit("set_show_settings", true);
      this.$store.commit("set_show_menu", false);
    },
    dynamicBackArrow() {
      //if seing ap/op, back to appointment
      //else back to appointments
      window.scrollTo(0,0);
      if (!sessionStorage.getItem("selection")) {
        this.$router.go(-1);
      } else if (JSON.parse(sessionStorage.getItem("selection")).type) {
        //delete generated select stored value if going back to details without save
        this.$store.commit("set_generated", {});
        //hide tasks (op/ap) and show details
        this.$store.commit("set_show_appointment_details", true);
        this.showAppointmentDetails = true;
        sessionStorage.removeItem("selection");
      }
    },

  },
};
</script>
<style lang="scss">
.nav-wrapper {
  top: 0;
  width: 100%;
  position: sticky;
  z-index: 10;
  background: #fff;
  padding: 25px 0 10px;

  .nav-row {
    text-align: right;
    display: flex;
    .fa-arrow-left {
      font-size: 22px;
    }

    svg {
      cursor: pointer;
    }
    .link {
      color: #000;
      position: relative;
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      left: 20px;
      cursor: pointer;
      &:hover {
        color: #009ae0;
      }
    }
    .show-settings-link {
      margin-left: auto;
    }
    .hide {
      display: none !important;
    }
  }
}
</style>