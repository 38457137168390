<template>
  <div class="checkbox-wrapper task-inner-wrapper" :id="compObj.id + '-wrapper'" v-if="showOnParentValue">
    <div :id="compObj.id+'-title'" class="subtitle">{{ compObj.label }}</div>
    <b-form-group>
      <b-form-radio
        v-model="selected"
        :id="compObj.id + '-yes'"
        :name="compObj.id + '-checkbox'"
        class="button-custom yes"
        :value="true"
        :ref="compObj.id + '-yes'"
        >Da</b-form-radio
      >
      <b-form-radio
        v-model="selected"
        :id="compObj.id + '-No'"
        :name="compObj.id + '-checkbox'"
        class="button-custom no"
        :value="false"
        :ref="compObj.id + '-No'"
        >Nu</b-form-radio
      >
    </b-form-group>
  </div>
</template>

  
<script>

export default {
  data() {
    return {
      userData: "",
      // selected: this.compObj.value===0?0:this.compObj.value===1?true:false,
      selected: this.compObj.value === 0 ? false : this.compObj.value === 1 ? true : 0,
      showFields: false,
    };
  },
  props: ["compObj","parentObjId","parentObjValue","nested"],
  watch: {
    selected: {
      handler: function (value) {
        //condition for showing nested components
         const self = this;
        if (value) {
          self.showFields = true;
        } else {
          self.showFields = false;
        }
         
         //save new value in store
         if (self.parentObjId === self.$store.state.taskData.id) {
          self.$store.commit("set_task_data", {
            initial: false,
            compObj: self.compObj,
            idToUpdate: self.compObj.id,
            newVal: self.selected,
            nested:self.nested,
            parentId:self.parentObjId
          });
        }

      },
      immediate: true,
    },
  },
  computed:{
    showOnParentValue(){
      if(this.compObj.showForParentValue===this.parentObjValue){
        return true;
      }else{
        return false;
      }
    }
  }
  
};
</script>
<style lang="scss">
.checkbox-wrapper {
  .button-custom {
    width: 50%;
    height: 55px;
    position: relative;
    display: inline-block;
    text-align: center;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
    line-height: 40px;
  }

  label,
  input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
  }

  input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
  }

  .yes input[type="radio"]:checked + label {
    background: #009850;
    color: #fff;
  }
  .no input[type="radio"]:checked + label {
    background: #ff513b;
    color: #fff;
  }

  label {
    padding: 5px;
    border: 1px solid #d8d8d8;

    cursor: pointer;
    z-index: 90;
  }
  .yes label {
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .no label {
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-left: 1px solid #d8d8d8;
  }

  label:hover {
    background: #ddd;
  }
  .custom-radio .custom-control-label::before {
    opacity: 0;
  }
}
</style>