<template>
<div class="view-wrapper">
  <div class="login">
    <div class="splash-content">
      <h1 class="splash-title">
        Gestioneaza programarile cu ener<span class="red-text">go!</span>
      </h1>
      <div class="splash-cta">
        <router-link to="/autentificare">
          <button id="splash-auth" class="btn red-btn">Autentificare</button>
        </router-link>
        <div class="pass-reset" v-if="showPass">
          <p>
            <span>Ai uitat parola? </span
            ><span class="reset-wrapper"
              ><router-link to="/recuperare-parola" class="blue-text"
                >Recupereaz-o</router-link
              ></span
            >
          </p>
        </div>
      </div>
      <div class="splash-img"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Login",
   data() {
    return {
      showPass:false
    }
   }
};
</script>
<style lang="scss" scoped>
#app,
.login {
  height: 100vh;
  overflow: hidden;
}
.splash-content {
  padding-top: 138px;
  @media (max-width: 410px) {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
  @media (max-width: 320px) {
    padding-top: 20px;
  }
  .splash-title {
    text-align: left;
    margin: 0 auto 58px auto;
    // max-width: 289px;
    max-width: 81%;
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
    @media (max-width: 410px) {
      margin: 0 auto 8vh auto;
      margin-top: auto;
    }
    @media (max-width: 320px) {
      margin-bottom: 1.5rem;
      font-size: 32px;
    }
  }
  .splash-cta {
    max-width: 81%;
    margin: 0 auto;
    @media (max-width: 410px) {
      margin-bottom: 1rem;
      width: 100%;
    }
    @media (max-width: 320px) {
      margin-bottom: 0;
    }
    .splash-auth {
      @media (max-width: 320px) {
        height: 50px !important;
      }
    }
  }
  .splash-img {
    background: url("../assets/images/energo assets.png");
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 312px;
    background-position: -21px 26px;
    @media (max-width: 320px) {
      min-height: 270px;
    }
  }
  .pass-reset {
    margin-bottom: 38px !important;
    @media (max-width: 410px) {
      margin-bottom: auto !important;
    }
  }
}
</style>
